<template>
  <el-card>
    <h3>Issuance of Financial Documents</h3> 

    <p>
      اختر الطالب او الطلبة الاخوة الذي سيتم اصدار لهم واصل مالي
      <strong>أمام إسم كل طالب سيكون إسم ولي أمره</strong>
    </p>

    <el-form :model="form" @submit.prevent="submitForm">
      <!-- Select Students (Multiple Selection) -->
      <el-select
      v-model="form.student_ids"
      multiple
      placeholder="Select students"
      @change="fetchParentAndFees"
      filterable
      :filter-method="filterByParentOrStudent"
    >
      <el-option
        v-for="student in filteredStudents"
        :key="student.id"
        :label="`${student.name} (إسم ولي الأمر: ${student.parentName})`"
        :value="student.id"
      />
    </el-select>
    

      <!-- Display Parent Name -->
      <el-form-item label="Parent Name" v-if="parent">
        <el-input v-model="formattedParentName" disabled></el-input>
      </el-form-item>

      <!-- Fee Amounts for Each Student -->
      <div v-for="studentId in form.student_ids" :key="studentId">
        <h5>رسوم الإشتراك للطالب/ة {{ getStudentNameById(studentId) }}</h5>

        <!-- Select Fees for Each Student -->
        <el-form-item label="اختر رسوم الإشتراك" required>
          <el-select v-model="studentFees[studentId]" multiple placeholder="Select fees for this student" @change="updateStudentFees(studentId)">
            <el-option v-for="fee in subscriptionFees" :key="fee.id" :label="`${fee.category} - ${fee.sub_category} - ${fee.amount}$`" :value="fee.id" />
          </el-select>
        </el-form-item>

        <!-- Display selected fees and amounts for the student -->
        <div v-for="feeId in studentFees[studentId]" :key="feeId">
          <el-form-item :label="`Amount for ${getFeeLabel(feeId)}`">
            <el-input :value="getFeeAmount(feeId)" type="number" disabled></el-input>
          </el-form-item>
        </div>
      </div>

      <!-- Select Treasury -->
      <el-form-item label="Select Treasury" required>
        <el-select v-model="form.treasury_id" placeholder="Select a treasury" filterable>
          <el-option v-for="treasury in treasuries" :key="treasury.id" :label="treasury.name" :value="treasury.id" />
        </el-select>
      </el-form-item>

      <!-- Payment Method -->
      <el-form-item label="Payment Method" required>
        <el-radio-group v-model="form.payment_method">
          <el-radio label="cash">Cash</el-radio>
          <el-radio label="instrument">Financial Instrument</el-radio>
        </el-radio-group>
      </el-form-item>

      <!-- Bank Information (Visible only if instrument is selected) -->
      <el-form-item v-if="form.payment_method === 'instrument'" label="Bank Name">
        <el-input v-model="form.bank_name" placeholder="Enter bank name" />
      </el-form-item>

      <el-form-item v-if="form.payment_method === 'instrument'" label="Branch Name">
        <el-input v-model="form.branch_name" placeholder="Enter branch name" />
      </el-form-item>

      <el-form-item v-if="form.payment_method === 'instrument'" label="Account Number">
        <el-input v-model="form.account_number" placeholder="Enter account number" />
      </el-form-item>

      <!-- Value Received -->
      <el-form-item label="Value Received" required>
        <el-input v-model.number="form.value_received" type="number" placeholder="Enter value received" />
      </el-form-item>

      <!-- Discount (Optional) -->
           <!-- Display Total Amount -->
    <el-form-item label="Total Amount">
      <el-input :value="totalAmount" type="number" disabled></el-input>
    </el-form-item>

      <el-form-item label="Discount (Optional)">
        <el-input v-model.number="form.discount" type="number" placeholder="Enter discount" />
      </el-form-item>

      <!-- Submit Form -->
      <el-form-item>
        <el-button type="primary" @click="submitForm">Issue Document</el-button>
      </el-form-item>
    </el-form>

    <!-- Show Receipt Number After Document is Issued -->
    <div v-if="receiptNumber">
      <el-alert title="Financial Document Issued" type="success" />
      <div>Receipt Number: {{ receiptNumber }}</div>
    </div>

    <!-- Optional: Display Logo -->
    <img :src="logo" alt="School Logo" class="logo"/>
  </el-card>
</template>

<script>
import axios from "axios";
import { ElMessage } from 'element-plus';
import logo from "@/assets/img/logo-school.png";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'https://sys.almasa-sch.ly/api',
});

export default {
  data() {
    return {
      students: [],
      filteredStudents: [],
      subscriptionFees: [],
      treasuries: [],
      parent: null,
      form: {
        student_ids: [],
        treasury_id: null,
        payment_method: '',
        value_received: '',
        bank_name: '',
        branch_name: '',
        account_number: '',
        discount: '',
      },
      studentFees: {}, // Stores selected fees for each student
      feeAmounts: {},  // Stores amounts for selected fees for each student
      receiptNumber: null,
      totalAmount: 0, // Stores the total amount for all selected students
    };
  },
  computed: {
    formattedParentName() {
      return this.parent ? `${this.parent.first_name} ${this.parent.last_name}` : '';
    }
  },
  methods: {
    filterByParentOrStudent(query) {
    if (!query) {
      this.filteredStudents = this.students;
    } else {
      this.filteredStudents = this.students.filter(student => {
        const studentNameMatches = student.name.toLowerCase().includes(query.toLowerCase());
        const parentNameMatches = student.parentName.toLowerCase().includes(query.toLowerCase());
        return studentNameMatches || parentNameMatches;
      });
    }
  },
  async fetchParentAndFees(studentIds) {
    const firstStudentId = studentIds[0];
    try {
      const response = await api.get(`/subscription-fees/${firstStudentId}`);
      this.subscriptionFees = response.data.subscriptionFees;
      this.parent = response.data.parent; // Get parent data
      this.totalAmount = 0; // Reset total amount
      // Reset studentFees and feeAmounts when students are selected/changed
      studentIds.forEach(studentId => {
        this.$set(this.studentFees, studentId, []);
      });
      // Recalculate total amount after fetching fees
      this.calculateTotalAmount();
    } catch (error) {
      // Handle error
    }
  },
  getStudentNameById(studentId) {
    const student = this.students.find(s => s.id === studentId);
    return student ? student.name : 'Unknown';
  },
  getFeeLabel(feeId) {
    const fee = this.subscriptionFees.find(f => f.id === feeId);
    return fee ? `${fee.category} - ${fee.sub_category}` : 'Unknown Fee';
  },
  getFeeAmount(feeId) {
    const fee = this.subscriptionFees.find(f => f.id === feeId);
    return fee ? fee.amount : 0;
  },
  updateStudentFees(studentId) {
    if (!this.studentFees[studentId]) {
      this.$set(this.studentFees, studentId, []);
    }
    this.calculateTotalAmount();
  },
  buildStudentFeesData() {
        const studentFeesData = {};
        const firstStudentId = this.form.student_ids[0];

        // Calculate total fees for all students and include in studentFeesData
        let totalFees = 0;

        this.form.student_ids.forEach(studentId => {
            studentFeesData[studentId] = this.studentFees[studentId].map(feeId => {
                const feeAmount = this.getFeeAmount(feeId);
                totalFees += feeAmount;
                return {
                    subscription_fee_id: feeId,
                    amount: feeAmount,
                };
            });
        });

        this.totalAmount = totalFees; // Set the total amount
        return studentFeesData;
    },
    calculateTotalAmount() {
        let totalFees = 0;

        // Calculate total fees for all selected students
        this.form.student_ids.forEach(studentId => {
            const studentTotal = (this.studentFees[studentId] || []).reduce((sum, feeId) => {
                return sum + this.getFeeAmount(feeId);
            }, 0);

            totalFees += studentTotal;
        });

        this.totalAmount = totalFees; // Update total amount
    },
  fetchStudents() {
    api.get('/students-index').then(response => {
      this.students = response.data.map(student => {
        return {
          ...student,
          parentName: `${student.parent.first_name} ${student.parent.last_name}`
        };
      });
      this.filteredStudents = this.students;
    }).catch(error => {
      console.error('Error fetching students:', error);
    });
  },
  fetchTreasuries() {
    api.get('/treasuries').then(response => {
      this.treasuries = response.data.filter(treasury => treasury.type === 'manual');
    }).catch(error => {
      console.error('Error fetching treasuries:', error);
      ElMessage.error('Error fetching treasuries.');
    });
  },
    submitForm() {
      if (!this.form.student_ids.length || !this.form.treasury_id) {
        ElMessage.error('Please fill in all required fields.');
        return;
      }

      const studentFeesData = this.buildStudentFeesData();

      api.post('/financial-document', {
        ...this.form,
        student_fees: studentFeesData,
      }).then(response => {
        ElMessage.success(response.data.message);
        this.receiptNumber = response.data.receipt_number;
      }).catch(error => {
        console.error('Error issuing financial document:', error);
        ElMessage.error('Failed to issue the financial document.');
      });
    }
  },
  mounted() {
    this.fetchStudents();
    this.fetchTreasuries();
  }
};
</script>